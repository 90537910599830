.mainbgcch {
  background: linear-gradient(rgb(248, 207, 207), rgb(199, 255, 255));
}

.updates {
  height: 22px;
  width: 100%;
  display: flex;
  /* background-color: #67E0A6; */
  background-color: #ddd4ff;
  position: relative;
  overflow: hidden;
  align-items: center;
}
.updatesleft {
  width: 10%;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  background-color: #cc2121;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  z-index: 2;
  clip-path: polygon(
    27.1% 0%,
    51.4% 0%,
    79% 3%,
    100% 50%,
    100% 50%,
    76.8% 100%,
    79% 100%,
    65.7% 100%,
    0% 100%,
    0% 0%
  );
}
.updatesright {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: bold;
  position: absolute;
  width: 90%;
}
.upinner {
  position: absolute;
  height: 100%;
  display: flex;
  right: -20%;
  animation: mymove 15s linear infinite;
  z-index: 1;
}
.upinner a {
  text-decoration: none;
  color: #b34d53;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 12px;
  margin-top: 5px;
  margin: 0px 10px;
}

@keyframes mymove {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-400%);
  }
}

.tonavbar1 {
  height: 20%;
  width: 100%;
  background-color: #111a5c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}
.tonavbar1 img {
  width: 53%;
}

@media screen and (max-width: 768px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 10%;
    height: 100%;
    font-size: 10px;
  }
  .upinner a {
    font-size: 10px;
    margin-top: 2px;
  }
  .new-factitle-1 {
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    width: 25%;
  }
  .new-factitle{
    display: flex;
    flex-direction: column;
    align-items: left;
    /* border: 2px solid red; */
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 12%;
    height: 100%;
    font-size: 10px;
  }
  .phname{
    font-size: 6px;
    height: auto;
  }
  .new-factitle-1 {
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    width: 25%;
  }
  .new-factitle{
    display: flex;
    flex-direction: column;
    align-items: left;
    /* border: 2px solid red; */
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 14%;
    height: 100%;
    font-size: 10px;
  }
  .phname{
    height: auto;
    font-size: 6px ;
  }
  .new-factitle-1 {
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    width: 25%;
  }
  .new-factitle{
    display: flex;
    flex-direction: column;
    align-items: left;
    /* border: 2px solid red; */
    width: 100%;
  }

}

@media screen and (max-width: 400px) {
  .updates {
    height: 20px;
  }
  .new-factitle-1 {
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    width: 25%;
  }
  .new-factitle{
    display: flex;
    flex-direction: column;
    /* justify-content: left; */
    border: 2px solid red; 
    width: 100%;
  }
  .updatesleft {
    width: 18%;
    height: 100%;
    font-size: 10px;
  }
}

@media screen and (max-width: 380px) {
  .tonavbar1 img {
    width: 250px;
  }
  .new-factitle{
    display: flex;
    flex-direction: column;
    /* justify-content: left; */
    /* border: 2px solid red;  */
    width: 100%;
  }
  
}
